import React from 'react';
import { Identity, useFeatureFlag } from '@theorchard/suite-frontend';
import { BrandIcon } from '@theorchard/suite-icons';
import { useParams } from 'react-router-dom';
import {
    UpperProfitCenter,
    VendorType,
} from 'src/apollo/definitions/globalTypes';
import { VendorIdType } from 'src/apollo/selectors';
import {
    BRAND_AB,
    BRAND_AWAL,
    BRAND_COLUMBIA,
    BRAND_HRS,
    BRAND_MSK,
    BRAND_ORCH,
    BRAND_SME,
    BRAND_SME_AFFILIATES,
    BRAND_SME_GERMANY,
    TOGGLE_OFF_STATE,
    TOGGLE_ON_STATE,
} from 'src/constants';
import {
    FIN_MODE_SUPPORTED_ACCOUNTS_ID,
    FinModeValue,
    PROFIT_CENTER_SUPPORTED_ACCOUNTS_ID,
    RCA_FIN_LABEL_ID,
    RCA_ID,
} from 'src/constants/account';
import {
    INSIGHTS_FIN_LABEL_MODE,
    INSIGHTS_PROFIT_CENTER,
} from 'src/constants/featuresFlags';
import { AccountRouteRawParams } from 'src/pages/account/types';
import { normalizeNumber } from 'src/utils/formatNumber';

export const getCompanyIcon = (value?: string) => {
    if (value === BRAND_ORCH || value === BRAND_HRS)
        return <BrandIcon size="24" brand="orchard" />;
    if (
        value === BRAND_SME ||
        value === BRAND_COLUMBIA ||
        value === BRAND_SME ||
        value === BRAND_SME_AFFILIATES ||
        value === BRAND_SME_GERMANY
    )
        return <BrandIcon brand="sme" size="24" />;
    if (value === BRAND_AWAL) return <BrandIcon brand="awal" size="24" />;
    if (value === BRAND_AB) return <BrandIcon brand="ab" size="24" />;
    if (value === BRAND_MSK) return <BrandIcon brand="msk" size="24" />;
    return null;
};

export const getAccountTypeTerm = (
    type?: VendorType | null,
    subaccountId = 0
) => {
    if (type === VendorType.D3) return 'D3';

    if (subaccountId > 0) return 'product.metadata.subAccount';
    return 'catalog.labelName';
};

export const getAccountId = (
    { vendorId = 0, subaccountId = 0 }: Partial<VendorIdType>,
    type?: VendorType | null
) => {
    if (type === VendorType.D3) return vendorId;

    if (subaccountId > 0) return subaccountId;

    return vendorId;
};

const RESOURCE_TYPE_VENDOR = 'Vendor';

export const getAccountLevelPermissionAccess = (identity: Identity): boolean =>
    identity.resources?.some(res => res.type === RESOURCE_TYPE_VENDOR) ?? false;

export const useFinModeOptions = (
    accountId?: number | null,
    finMode?: FinModeValue
) => {
    const isFinModeEnabled = useFeatureFlag(INSIGHTS_FIN_LABEL_MODE);
    const shouldShowFinModeData =
        isFinModeEnabled && finMode === TOGGLE_ON_STATE;
    const isAccountSupported = accountId
        ? FIN_MODE_SUPPORTED_ACCOUNTS_ID.includes(accountId)
        : false;
    const isFinModeSupported = isAccountSupported && isFinModeEnabled;

    if (!accountId || !shouldShowFinModeData)
        return {
            shouldShowFinModeData,
            isFinModeSupported,
            finLabelIds: [],
        };

    const finLabelIds = accountId === RCA_ID ? [RCA_FIN_LABEL_ID] : [];

    return {
        shouldShowFinModeData: shouldShowFinModeData && isAccountSupported,
        isFinModeSupported,
        finLabelIds,
    };
};

export const useProfitCenterSettings = (
    finMode = TOGGLE_OFF_STATE,
    selectedProfitValue?: string
) => {
    const isFinModeFFEnabled = useFeatureFlag(INSIGHTS_FIN_LABEL_MODE);
    const isProfitCenterFFEnabled = useFeatureFlag(INSIGHTS_PROFIT_CENTER);
    const params = useParams<AccountRouteRawParams>();
    const accountId = normalizeNumber(params.accountId) ?? 0;
    const shouldShowProfitCenterFilter =
        isFinModeFFEnabled &&
        isProfitCenterFFEnabled &&
        PROFIT_CENTER_SUPPORTED_ACCOUNTS_ID.includes(accountId);
    const isProfitCenterFilterDisabled = finMode === TOGGLE_OFF_STATE;
    const resolvedProfitCenterValue = selectedProfitValue
        ? [
              UpperProfitCenter[
                  selectedProfitValue.toUpperCase() as UpperProfitCenter
              ],
          ]
        : (Object.keys(UpperProfitCenter) as UpperProfitCenter[]);

    return {
        shouldShowProfitCenterFilter,
        isProfitCenterFilterDisabled,
        resolvedProfitCenterValue:
            shouldShowProfitCenterFilter && !isProfitCenterFilterDisabled
                ? resolvedProfitCenterValue
                : [],
    };
};
