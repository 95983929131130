import { formatDate, formatMessage } from '@theorchard/suite-frontend';
import dayjs from 'dayjs';
import { first, last } from 'lodash';
import { SOURCE_DATE_FORMAT } from 'src/constants';
import { SourceError } from 'src/constants/stores';
import {
    STATUS_ERROR_CLASSNAME,
    STATUS_UPDATED_CLASSNAME,
    STATUS_WARNING_CLASSNAME,
    TERMS,
} from './constants';

export const getSourcesStatusClass = (
    error?: SourceError,
    missingDates?: string[]
) => {
    if (error) return STATUS_ERROR_CLASSNAME;

    return missingDates ? STATUS_WARNING_CLASSNAME : STATUS_UPDATED_CLASSNAME;
};

export const getSourceTerm = (error?: SourceError, missingDates?: string[]) => {
    let term = TERMS.UPDATED;

    if (missingDates) term = TERMS.MISSING_DATES;
    if (error) term = TERMS.ERROR;

    return formatMessage(term);
};

export const getWarningTooltipId = (storeId: number) =>
    `${storeId}-warning-tooltip`;

export const getMissingDatesRanges = (missingDates: string[]) => {
    return missingDates.reduce(
        (dateRanges: string[][], currentDate, dateIndex) => {
            // If the current iterable date is already included in one of date ranges, skip it
            if (dateRanges.some(date => date.includes(currentDate)))
                return dateRanges;

            // Add date and all adjacent dates next to it
            const adjacentDates: string[] = [];

            missingDates.slice(dateIndex).forEach((date, index) => {
                const isDateAdjacent =
                    dayjs(date).diff(dayjs(currentDate), 'day') === index;
                if (isDateAdjacent) {
                    adjacentDates.push(date);
                }
            });

            return [...dateRanges, adjacentDates];
        },
        []
    );
};

export const getMissingDatesTooltip = (missingDates: string[]) => {
    if (missingDates?.length === 1)
        formatDate(missingDates[0], SOURCE_DATE_FORMAT);

    const ranges = getMissingDatesRanges(missingDates);
    const tooltipText = ranges
        .map(dates => {
            const firstDate = formatDate(first(dates), SOURCE_DATE_FORMAT);
            const lastDate =
                dates.length > 1
                    ? formatDate(last(dates), SOURCE_DATE_FORMAT)
                    : '';
            return `${firstDate}${dates.length > 1 ? ' - ' : ''}${lastDate}`;
        })
        .join(', ');

    return tooltipText;
};
